var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-md-12 col-lg-12" },
        [
          _c(
            "q-form",
            { ref: "editForm" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "중장비 기본정보" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable
                            ? _c(
                                "q-btn",
                                {
                                  staticClass: "custom-btn",
                                  attrs: {
                                    icon: "help",
                                    color: "deep-purple-6",
                                    "text-color": "white",
                                    align: "center",
                                    round: "",
                                  },
                                },
                                [
                                  _c(
                                    "q-tooltip",
                                    {
                                      attrs: {
                                        anchor: "bottom left",
                                        self: "top left",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "tooltipCustomTop" },
                                        [_vm._v(" 설명 ")]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "tooltipCustom" },
                                        [
                                          _vm._v(
                                            " o 안전검사일은 유효검사기간에 맞춰 설정됩니다."
                                          ),
                                          _c("br"),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: { label: "저장", icon: "save" },
                              })
                            : _vm._e(),
                          _c("c-btn", {
                            attrs: {
                              label: "삭제",
                              editable: _vm.editable,
                              icon: "remove",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-plant", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            changeItem: _vm.changeItem,
                            type: "edit",
                            name: "plantCd",
                          },
                          model: {
                            value: _vm.tabParam.plantCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.tabParam, "plantCd", $$v)
                            },
                            expression: "tabParam.plantCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-dept", {
                          attrs: {
                            type: "edit",
                            required: "",
                            label: "담당부서",
                            name: "departmentDeptCd",
                          },
                          model: {
                            value: _vm.tabParam.departmentDeptCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.tabParam, "departmentDeptCd", $$v)
                            },
                            expression: "tabParam.departmentDeptCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-field", {
                          attrs: {
                            editable: _vm.editable,
                            label: "담당자",
                            type: "user",
                            name: "departmentUserId",
                          },
                          model: {
                            value: _vm.tabParam.departmentUserId,
                            callback: function ($$v) {
                              _vm.$set(_vm.tabParam, "departmentUserId", $$v)
                            },
                            expression: "tabParam.departmentUserId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "관리번호",
                            name: "managerNo",
                          },
                          model: {
                            value: _vm.tabParam.managerNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.tabParam, "managerNo", $$v)
                            },
                            expression: "tabParam.managerNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            required: "",
                            disabled: _vm.disabled,
                            editable: _vm.editable,
                            label: "중장비명",
                            name: "facilityName",
                          },
                          model: {
                            value: _vm.tabParam.facilityName,
                            callback: function ($$v) {
                              _vm.$set(_vm.tabParam, "facilityName", $$v)
                            },
                            expression: "tabParam.facilityName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            label: "기기번호",
                            name: "deviceNo",
                          },
                          model: {
                            value: _vm.tabParam.deviceNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.tabParam, "deviceNo", $$v)
                            },
                            expression: "tabParam.deviceNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            required: "",
                            type: "date",
                            editable: _vm.editable,
                            label: "반입일",
                            name: "carryinDate",
                          },
                          model: {
                            value: _vm.tabParam.carryinDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.tabParam, "carryinDate", $$v)
                            },
                            expression: "tabParam.carryinDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            editable: _vm.editable,
                            type: "date",
                            range: true,
                            label: "사용기간",
                            name: "useDate",
                          },
                          model: {
                            value: _vm.useDate,
                            callback: function ($$v) {
                              _vm.useDate = $$v
                            },
                            expression: "useDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3",
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-4" },
                            [
                              _c("c-text", {
                                attrs: {
                                  editable: _vm.editable,
                                  suffix: "년",
                                  type: "number",
                                  label: "안전검사유효기간",
                                  name: "checkValidPeriod",
                                },
                                on: { dataChange: _vm.datachangeDate },
                                model: {
                                  value: _vm.tabParam.checkValidPeriod,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.tabParam,
                                      "checkValidPeriod",
                                      $$v
                                    )
                                  },
                                  expression: "tabParam.checkValidPeriod",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-4" },
                            [
                              _c("c-datepicker", {
                                attrs: {
                                  editable: _vm.editable,
                                  type: "date",
                                  label: "안전검사일",
                                  start: _vm.start,
                                  end: _vm.end,
                                  name: "safetyDate",
                                },
                                on: { datachange: _vm.datachangeDate },
                                model: {
                                  value: _vm.tabParam.safetyDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.tabParam, "safetyDate", $$v)
                                  },
                                  expression: "tabParam.safetyDate",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-4" },
                            [
                              _c(
                                "q-chip",
                                {
                                  class:
                                    _vm.tabParam.validFlagName ===
                                    "유효기간 초과"
                                      ? "blinking"
                                      : null,
                                  attrs: {
                                    color: _vm.setTagColor(
                                      _vm.tabParam.validFlagName ===
                                        "유효기간 초과"
                                        ? "Y"
                                        : _vm.tabParam.validFlagName ===
                                          "입력중"
                                        ? "NE"
                                        : "orange"
                                    ),
                                    outline: "",
                                    square: "",
                                    "text-color": "white",
                                  },
                                },
                                [
                                  _vm.tabParam.validFlagName === "유효기간 초과"
                                    ? _c("q-avatar", {
                                        attrs: {
                                          icon: "alarm",
                                          color: "red",
                                          "text-color": "white",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.tabParam.validFlagName === "입력중"
                                    ? _c("q-avatar", {
                                        attrs: {
                                          icon: "create",
                                          color: "grey-5",
                                          "text-color": "white",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.setTagName(
                                          _vm.tabParam.validFlagName ===
                                            "유효기간 초과"
                                            ? "Y"
                                            : _vm.tabParam.validFlagName
                                        )
                                      ) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "실소유자 및 운전원",
                            name: "operationAtor",
                          },
                          model: {
                            value: _vm.tabParam.operationAtor,
                            callback: function ($$v) {
                              _vm.$set(_vm.tabParam, "operationAtor", $$v)
                            },
                            expression: "tabParam.operationAtor",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-field", {
                          attrs: {
                            editable: _vm.editable,
                            label: "관리책임자",
                            plantCd: _vm.tabParam.plantCd,
                            vendorCd: _vm.tabParam.vendorCd,
                            data: _vm.tabParam,
                            isWrite: true,
                            changeItem: _vm.changeItem,
                            userName: "managerUserName",
                            name: "managerUserId",
                          },
                          model: {
                            value: _vm.tabParam.managerUserId,
                            callback: function ($$v) {
                              _vm.$set(_vm.tabParam, "managerUserId", $$v)
                            },
                            expression: "tabParam.managerUserId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "설치보관위치",
                            name: "installLocation",
                          },
                          model: {
                            value: _vm.tabParam.installLocation,
                            callback: function ($$v) {
                              _vm.$set(_vm.tabParam, "installLocation", $$v)
                            },
                            expression: "tabParam.installLocation",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "제원",
                            name: "specifications",
                          },
                          model: {
                            value: _vm.tabParam.specifications,
                            callback: function ($$v) {
                              _vm.$set(_vm.tabParam, "specifications", $$v)
                            },
                            expression: "tabParam.specifications",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "점검조치",
                            name: "checkAction",
                          },
                          model: {
                            value: _vm.tabParam.checkAction,
                            callback: function ($$v) {
                              _vm.$set(_vm.tabParam, "checkAction", $$v)
                            },
                            expression: "tabParam.checkAction",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c(
                          "c-table",
                          {
                            ref: "table",
                            attrs: {
                              title: "안전대책 목록",
                              columns: _vm.grid.columns,
                              gridHeight: _vm.grid.height,
                              data: _vm.tabParam
                                .machinerySafetyMeasureModelList,
                              filtering: false,
                              columnSetting: false,
                              isExcelDown: false,
                              usePaging: false,
                              editable: _vm.editable,
                              selection: "multiple",
                              rowKey: "equipmentCd",
                            },
                          },
                          [
                            _c(
                              "template",
                              { slot: "table-button" },
                              [
                                _c(
                                  "q-btn-group",
                                  { attrs: { outline: "" } },
                                  [
                                    _vm.editable && !_vm.disabled
                                      ? _c("c-btn", {
                                          attrs: {
                                            showLoading: false,
                                            label: "추가",
                                            icon: "add",
                                          },
                                          on: { btnClicked: _vm.addRisk },
                                        })
                                      : _vm._e(),
                                    _vm.editable &&
                                    !_vm.disabled &&
                                    _vm.tabParam.machinerySafetyMeasureModelList
                                      .length > 0
                                      ? _c("c-btn", {
                                          attrs: {
                                            showLoading: false,
                                            label: "제외",
                                            icon: "remove",
                                          },
                                          on: { btnClicked: _vm.removeRisk },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("br"),
                        _c("c-upload", {
                          attrs: {
                            attachInfo: _vm.attachInfo,
                            editable: _vm.editable,
                            label: "관련 중장비 파일",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }