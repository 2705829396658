<template>
    <div class="row">
      <div class="col-md-12 col-lg-12">
        <q-form ref="editForm">
          <c-card
              title="중장비 기본정보"
              class="cardClassDetailForm"
          >
            <template slot="card-button">
              <q-btn-group outline>
                <q-btn
                  v-if="editable"
                  icon="help"
                  color="deep-purple-6"
                  text-color="white"
                  class="custom-btn"
                  align="center"
                  round>
                  <q-tooltip anchor="bottom left" self="top left">
                    <div class="tooltipCustomTop">
                      설명
                    </div>
                    <div class="tooltipCustom">
                    o 안전검사일은 유효검사기간에 맞춰 설정됩니다.<br>
                    </div>
                  </q-tooltip>
                </q-btn>
                <c-btn
                  v-if="editable"
                  label="저장"
                  icon="save"
                />
                <c-btn
                  label="삭제"
                  :editable="editable"
                  icon="remove"
                />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-plant
                  required
                  :editable="editable"
                  :changeItem="changeItem"
                  type="edit"
                  name="plantCd"
                  v-model="tabParam.plantCd"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-dept type="edit" required  label="담당부서" name="departmentDeptCd" v-model="tabParam.departmentDeptCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-field
                  :editable="editable"
                  label="담당자"
                  type="user" 
                  name="departmentUserId"
                  v-model="tabParam.departmentUserId">
                </c-field>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  label="관리번호"
                  name="managerNo"
                  v-model="tabParam.managerNo"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  required
                  :disabled="disabled"
                  :editable="editable"
                  label="중장비명"
                  name="facilityName"
                  v-model="tabParam.facilityName"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  required
                  :editable="editable"
                  label="기기번호"
                  name="deviceNo"
                  v-model="tabParam.deviceNo"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-datepicker
                  required
                  type="date"
                  :editable="editable"
                  label="반입일"
                  name="carryinDate"
                  v-model="tabParam.carryinDate"
                >
                </c-datepicker>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-datepicker
                  :editable="editable"
                  type="date"
                  :range="true"
                  label="사용기간"
                  name="useDate"
                  v-model="useDate"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div class="row">
                  <div class="col-4">
                    <c-text
                      :editable="editable"
                      suffix="년"
                      type="number"
                      label="안전검사유효기간"
                      name="checkValidPeriod"
                      v-model="tabParam.checkValidPeriod"
                      @dataChange="datachangeDate"
                    >
                    </c-text>
                  </div>
                  <div class="col-4">
                    <c-datepicker
                      :editable="editable"
                      type="date"
                      label="안전검사일"
                      :start="start"
                      :end="end"
                      name="safetyDate"
                      v-model="tabParam.safetyDate"
                      @datachange="datachangeDate"
                    />
                  </div>
                  <div class="col-4">
                    <q-chip
                      :color="setTagColor(tabParam.validFlagName === '유효기간 초과' ? 'Y' :
                              tabParam.validFlagName === '입력중' ? 'NE' : 'orange')"
                      :class="tabParam.validFlagName ==='유효기간 초과' ? 'blinking' : null"
                      outline square
                      text-color="white">
                      <q-avatar v-if="tabParam.validFlagName==='유효기간 초과'" icon="alarm" color="red" text-color="white" />
                      <q-avatar v-if="tabParam.validFlagName==='입력중'" icon="create" color="grey-5" text-color="white" />
                      {{setTagName(tabParam.validFlagName === '유효기간 초과' ? 'Y' : tabParam.validFlagName)}}
                    </q-chip>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  label="실소유자 및 운전원"
                  name="operationAtor"
                  v-model="tabParam.operationAtor"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-field
                  :editable="editable"
                  label="관리책임자"
                  :plantCd="tabParam.plantCd"
                  :vendorCd="tabParam.vendorCd"
                  :data="tabParam"
                  :isWrite="true"
                  :changeItem="changeItem"
                  userName="managerUserName"
                  name="managerUserId"
                  v-model="tabParam.managerUserId"
                >
                </c-field>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  label="설치보관위치"
                  name="installLocation"
                  v-model="tabParam.installLocation"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  label="제원"
                  name="specifications"
                  v-model="tabParam.specifications"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  label="점검조치"
                  name="checkAction"
                  v-model="tabParam.checkAction"
                >
                </c-text>
              </div>
            </template>
          </c-card>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-table
                  ref="table"
                  title="안전대책 목록"
                  :columns="grid.columns"
                  :gridHeight="grid.height"
                  :data="tabParam.machinerySafetyMeasureModelList"
                  :filtering="false"
                  :columnSetting="false"
                  :isExcelDown="false"
                  :usePaging="false"
                  :editable="editable"
                  selection="multiple"
                  rowKey="equipmentCd"
                >
                <template slot="table-button">
                  <q-btn-group outline>
                    <c-btn
                      v-if="editable && !disabled"
                      :showLoading="false"
                      label="추가"
                      icon="add"
                      @btnClicked="addRisk"
                    />
                    <c-btn
                      v-if="
                        editable &&
                        !disabled &&
                        tabParam.machinerySafetyMeasureModelList.length > 0
                      "
                      :showLoading="false"
                      label="제외"
                      icon="remove"
                      @btnClicked="removeRisk"
                    />
                  </q-btn-group>
                </template>
                </c-table>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <br>
                <c-upload 
                  :attachInfo="attachInfo"
                  :editable="editable"
                  label="관련 중장비 파일">
                </c-upload>
              </div>
            </div>
          </div>
        </q-form>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </div>
</template>
<script>
import { uid } from "quasar";
export default {
  name: "hazardEquipmentInfo",
  props: {
    tabParam: {
      type: Object,
      default: () => ({
        saiContructFacilityId: '',
        plantCd: '',
        processCd: '',
        vendorCd: '',
        facilityTypeId: '',
        facilityTypeCd: '',
        facilityTypeName: '',
        facilityName: '',
        deviceNo: '',
        installLocation: '',
        carryinDate: '',
        useStartYmd: '',
        useEndYmd: '',
        safetyDate: '',
        safetyResultFlag: 'Y',
        checkAction: '',
        managerNo: '',
        managerUserId: '',
        managerUserName: '',
        regUserId: '',
        regDt: '',
        chgUserId: '',
        chgDt: '',
        validFlagName: '일 남음',
        specifications: '',
        operationAtor: '',
        checkValidPeriod: '',

        machinerySafetyMeasureModelList: [],
        deleteMachinerySafetyMeasureModelList: [],
      }),
    },
    useDate: {
      type: Array,
    },
    attachInfo: {
      type: Object,
      default: () => ({
        isSubmit: '',
        taskClassCd: 'FORK_LIFT_INFO',
        taskKey: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      changeItem: {
        plant: '',
        process: '',
        vendor: '',
      },
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      saveUrl: "transactionConfig.sai.machinery.insert.url",
      saveType: "POST",
      isSave: false,
      checkUrl: "",
      insertUrl: "",
      disabled: false,
      colorItems: [
        { colorCode: 'Y', colorName: '유효기간 초과', colorClass: 'red' },
        { colorCode: 'NE', colorName: '입력중', colorClass: 'grey-5' },
      ],
      grid: {
        columns: [
          {
            name: 'riskFactors',
            field: 'riskFactors',
            label: '위험요인',
            align: 'left',
            type: 'text',
            style: 'width:30%',
            sortable: false
          },
          {
            name: 'safetyMeasure',
            field: 'safetyMeasure',
            label: '개선대책',
            align: 'left',
            type: 'text',
            style: 'width:70%',
            sortable: false,
          },
        ],
        data: [],
        height: '400px'
      },
      deleteUrl: "",
      updateUrl: "",
      updateMode: false,
      getUrl: "",
      flagItems: [
        { code: "N", codeName: "통과" },
        { code: "Y", codeName: "미통과" },
      ],
      start: '',
      end: '',
    };
  },
    beforeCreate() {},
    created() {},
    beforeMount() {
    },
    mounted() {
      this.init();
    },
    beforeDestroy() {},
    computed: {
    },
    watch: {
      'tabParam.checkValidPeriod'() {
        this.datachangeDate();
      }
    },
    methods: {
    init() {
      this.tabParam.machinerySafetyMeasureModelList = [
        {
          riskFactors: '위험요인1',
          safetyMeasure: '개선대책1'
        },
        {
          riskFactors: '위험요인2',
          safetyMeasure: '개선대책2'
        },
        {
          riskFactors: '위험요인3',
          safetyMeasure: '개선대책3'
        },
      ]
    },
    addRisk() {
      this.tabParam.machinerySafetyMeasureModelList.splice(0, 0, {
        saiContructFacilityId: this.tabParam.saiContructFacilityId,
        saiContructFacilityMeasureId: uid(),
        riskFactors: '',
        safetyMeasure: '',
        regUserId: this.$store.getters.user.userId,
        editFlag: "C",
      });
    },
    removeRisk() {
      let selectData = this.$refs["table"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "안내",
          message: "선택된 항목이 없습니다.", 
          type: "warning", // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, (item) => {
          if (!this.tabParam.deleteMachinerySafetyMeasureModelList) {
            this.tabParam.deleteMachinerySafetyMeasureModelList = [];
          }
          if (
            this.$_.findIndex(this.tabParam.deleteMachinerySafetyMeasureModelList, {
              saiContructFacilityMeasureId: item.saiContructFacilityMeasureId,
            }) === -1 &&
            item.editFlag !== "C"
          ) {
            this.tabParam.deleteMachinerySafetyMeasureModelList.push(item);
          }
          this.tabParam.machinerySafetyMeasureModelList = this.$_.reject(
            this.tabParam.machinerySafetyMeasureModelList,
            item
          );
        });
      }
    },
    setTagColor(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { colorCode : data })
        return color ? color.colorClass : 'blue';
      }
    },
    setTagName(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { colorCode: data })
        return color ? color.colorName : data;
      }
    },
    datachangeDate() {
      // 유효기간 
      this.end = this.$comm.getToday();
      this.start = this.$comm.getCalculatedDate(
            this.$comm.getToday(),
            '-' + this.tabParam.checkValidPeriod + 'y',
            'YYYY-MM-DD',
            'YYYY-MM-DD'
          )
      this.start = this.$comm.getCalculatedDate(
            this.start,
            '1d',
            'YYYY-MM-DD',
            'YYYY-MM-DD'
          )
      if (!this.tabParam.safetyDate) {
        this.tabParam.validFlagName = '입력중'
      } else {
        var stDate = new Date(this.$comm.getCalculatedDate(
            this.tabParam.safetyDate,
            this.tabParam.checkValidPeriod + 'y',
            'YYYY-MM-DD',
            'YYYY-MM-DD'
          ));
        var endDate = new Date(this.end);

        var btMs = stDate.getTime() - endDate.getTime();
        var btDay = btMs / (1000*60*60*24);

        if (btDay < 0) {
          this.tabParam.validFlagName = '유효기간 초과'
        } else {
          this.tabParam.validFlagName = btDay + '일 남음'
        }
      }
    },
  },
};
</script>

<style>
.blinking {
  -webkit-animation:blink 3s ease-in-out infinite alternate;
  -moz-animation:blink 3s ease-in-out infinite alternate;
  animation:blink 3s ease-in-out infinite alternate;
}
@-webkit-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@-moz-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
</style>